$button-text-hover-background-color: transparent;

@import '~bulma';

.header {
  padding: 20px 0 0 20px;
}

.main {
  margin: 0 20px 0 20px;
}

.clickable {
  cursor: pointer;
}

.icon-nav:hover {
  color: #99ddff;   
}
